import axios from 'axios';
import Cookies from 'universal-cookie';
import { CookiesConstant } from '@/services/api/types';
import { compact } from 'lodash';
import { envs } from '@/constants';

export const cookies = new Cookies();

export const cookieOptions = { path: '/', domain: 'ucall.vn' };

export const baseURL = envs.API_URL;

export const setCookie = (key: string, value: string | undefined) => {
  cookies.set(key, value, cookieOptions);
  cookies.set(key, value, { ...cookieOptions, domain: 'localhost' });
  cookies.set(key, value, { path: '/' });
};

export const getAccessToken = () => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const prefixedAccessToken = searchParams.get('access');

  if (prefixedAccessToken) {
    setCookie(CookiesConstant.ACCESS_TOKEN, prefixedAccessToken);
    return prefixedAccessToken;
  }

  return cookies.get(CookiesConstant.ACCESS_TOKEN);
};

export const removeCookie = (key: string) => {
  cookies.remove(key, cookieOptions);
  cookies.remove(key);
};

export const checkCookiesParamsLandingPage = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params['date']) {
    setCookie('date', params['date']);
  }
  if (params['channel']) {
    setCookie('channel', params['channel']);
  }
  if (params['marketer']) {
    setCookie('marketer', params['marketer']);
  }
  if (params['label']) {
    setCookie('label', params['label']);
  }
  if (params['content']) {
    setCookie('content', params['content']);
  }
  if (params['contact']) {
    setCookie('contact', params['contact']);
  }
  if (params['label']) {
    setCookie('contact', params['label']);
  }
  if (params['referrer']) {
    setCookie('referrer', params['referrer']);
  }
  if (params['gclid']) {
    setCookie('gclid', params['gclid']);
  }
  if (params['fbclid']) {
    setCookie('fbclid', params['fbclid']);
  }
};

export const cookieParamsLandingPage = () => {
  return {
    date: cookies.get('date'),
    channel: cookies.get('channel'),
    marketer: cookies.get('marketer'),
    label: cookies.get('label'),
    content: cookies.get('content'),
    contact: cookies.get('contact'),
    referrer: cookies.get('referrer'),
    gclid: cookies.get('gclid'),
    fbclid: cookies.get('fbclid'),
  };
};

const axiosInstance = axios.create({
  baseURL,
  headers: getAccessToken()
    ? {
        Authorization: `token ${cookies.get(CookiesConstant.ACCESS_TOKEN)}`,
        'X-CSRFToken': `${cookies.get(CookiesConstant.CSRFToken)}`,
      }
    : cookies.get(CookiesConstant.API_KEY)
    ? {
        'x-api-key': `${cookies.get(CookiesConstant.API_KEY)}`,
      }
    : {},
});

axiosInstance.defaults.withCredentials = true;

cookies.addChangeListener(({ name, value }) => {
  if (name === CookiesConstant.ACCESS_TOKEN) {
    const loginInterceptor = axiosInstance.interceptors.request.use(function (config) {
      config.headers = {
        ...config.headers,
        Authorization: `token ${value}`,
      };
      return config;
    });
    if (!value || value === '') {
      axiosInstance.interceptors.request.eject(loginInterceptor);
    }
  }
  if (name === CookiesConstant.API_KEY) {
    const loginInterceptor = axiosInstance.interceptors.request.use(function (config) {
      config.headers = {
        ...config.headers,
        'x-api-key': `${value}`,
      };
      return config;
    });
    if (!value || value === '') {
      axiosInstance.interceptors.request.eject(0);
      axiosInstance.interceptors.request.eject(loginInterceptor);
    }
  }
  axiosInstance.interceptors.request.handlers = compact(
    axiosInstance.interceptors.request.handlers,
  );
});

export const nestedApiRoutes = (COMPANY_ID: string) => {
  return {
    auth_group: {
      me: `/company/${COMPANY_ID}/auth_group/me`,
      detail: (AUTH_GROUP_ID: string) => `/company/${COMPANY_ID}/auth_group/${AUTH_GROUP_ID}`,
      list: `/company/${COMPANY_ID}/auth_group`,
    },
    job: {
      list: `/job`,
    },
    voice: {
      list: '/voice',
    },
    affiliate: {
      me: {
        list: `/company/${COMPANY_ID}/affiliate_partner/me`,
      },
      companies: {
        list: `/company/${COMPANY_ID}/affiliate_partner/companies`,
      },
      transaction: {
        list: (AFFILIATE_ID: string) =>
          `/company/${COMPANY_ID}/affiliate_partner/${AFFILIATE_ID}/transactions`,
      },
    },
    permission: {
      list: `/company/${COMPANY_ID}/permission`,
      all: `/company/${COMPANY_ID}/permission/all`,
      role: (ROLE_ID: string) => `/company/${COMPANY_ID}/permission/${ROLE_ID}`,
    },
    agent: {
      me: `/company/${COMPANY_ID}/agent/me`,
      detail: (AGENT_ID) => `/company/${COMPANY_ID}/agent/${AGENT_ID}`,
    },
    hotlinefree: {
      me: `/company/${COMPANY_ID}/hotlinefree`,
      detail: (HOTLINE_ID: string) => `/company/${COMPANY_ID}/hotlinefree/${HOTLINE_ID}`,
      register_hotline: (HOTLINE_ID: string) =>
        `/company/${COMPANY_ID}/hotlinefree/${HOTLINE_ID}/register_hotline`,
    },

    wallet: {
      detail: `/company/${COMPANY_ID}/wallet`,
      list: `/company/${COMPANY_ID}/transaction_wallet`,
      transaction_detail: (TRANSACTION_WALLET_ID: string) =>
        `/company/${COMPANY_ID}/transaction_wallet/${TRANSACTION_WALLET_ID}`,
    },

    call: {
      list: `/company/${COMPANY_ID}/call`,
      detail: (MANUAL_CALL_ID: string) => `/company/${COMPANY_ID}/call/${MANUAL_CALL_ID}`,
    },
    vnpay: `/company/${COMPANY_ID}/vnpay/payment`,
    transaction: {
      list: `/company/${COMPANY_ID}/transaction`,
    },
    file: {
      list: '/file',
    },
    tawk_chat: '/tawk-chat/',
    package: `/package`,
    profile: {
      list: `/company/${COMPANY_ID}/profilev2`,
      companyprofileraw: `/company/${COMPANY_ID}/companyprofileraw`,
      detail: (PROFILE_ID: string) => `/company/${COMPANY_ID}/profilev2/${PROFILE_ID}`,
      limit_budget: (PROFILE_ID: string) => `/company/${COMPANY_ID}/companyprofile/${PROFILE_ID}/limit_budget`,
    },
    group: {
      list: `/company/${COMPANY_ID}/group`,
      detail: (PROFILE_GROUP_ID: string) => `/company/${COMPANY_ID}/group/${PROFILE_GROUP_ID}`,
    },
    products: {
      // list: `/company/${COMPANY_ID}/product/AUTOCALL`,
      packages: (PRODUCT_ID: string) => `/company/${COMPANY_ID}/package?category=${PRODUCT_ID}`,
      subscriptions: (PRODUCT_ID: string) =>
        `/company/${COMPANY_ID}/subscription?code=${PRODUCT_ID}`,
      subscription_transactions: (PRODUCT_ID: string) =>
        `/company/${COMPANY_ID}/subscription/${PRODUCT_ID}/transaction`,
      payment: `/company/${COMPANY_ID}/payment`,
      budget_allocation: `/company/${COMPANY_ID}/subscription/budget_allocation`,
    },
    ordercart: {
      list: `/company/${COMPANY_ID}/ordercart`,
      add: `/company/${COMPANY_ID}/ordercart/add`,
      new: `/company/${COMPANY_ID}/ordercart/new`,
      topup_wallet: `/company/${COMPANY_ID}/ordercart/topup_wallet`,
      detail: (ORDER_ID: string) => `/company/${COMPANY_ID}/ordercart/${ORDER_ID}`,
      report: `/company/${COMPANY_ID}/ordercart/report`,
    },
    campaign_configs: {
      list: `/company/${COMPANY_ID}/campaign_config`,
    },
    notifications: {
      list: `/notification`,
      detail: (NOTIFICATION_ID: string) => `/notification/${NOTIFICATION_ID}`,
    },
    devices: {
      register: '/device',
    },
    guide: {
      detail: '/guide',
    },
    company: {
      current_company: `/company/${COMPANY_ID}`,
      companyprofile: `/company/${COMPANY_ID}/companyprofile`,
      budget_allocation: `/company/${COMPANY_ID}/budget_allocation`,
      block_price: `/company/${COMPANY_ID}/block_price`,
      hotline_type_default: `/company/${COMPANY_ID}/hotline_type_default`,
    },
    campaign_report: {
      export_group: `/company/${COMPANY_ID}/campaign_report/export_group`,
    },
    call_bot_scenario: {
      list: `/company/${COMPANY_ID}/call_bot_scenario`,
      detail: (CALL_BOT_SCENARIO_ID: string) =>
        `/company/${COMPANY_ID}/call_bot_scenario/${CALL_BOT_SCENARIO_ID}`,
      verify: (CALL_BOT_SCENARIO_ID: string) =>
        `/company/${COMPANY_ID}/call_bot_scenario/${CALL_BOT_SCENARIO_ID}/verify`,
    },

    call_bot_scenario_company_sample: {
      list: `/company/${COMPANY_ID}/call_bot_scenario_company_sample`,
      detail: (CALL_BOT_SCENARIO_COMPANY_ID: string) =>
        `/company/${COMPANY_ID}/call_bot_scenario_company_sample/${CALL_BOT_SCENARIO_COMPANY_ID}`,
      verify: (CALL_BOT_SCENARIO_ID: string) =>
        `/company/${COMPANY_ID}/call_bot_scenario_company_sample/${CALL_BOT_SCENARIO_ID}/verify`,
    },

    call_bot_scenario_sample: {
      list: '/call_bot_scenario_sample',
    },
    customer: {
      list: `/company/${COMPANY_ID}/customer`,
      import_excel: `/company/${COMPANY_ID}/customer/import_excel`,
      delete_all: `/company/${COMPANY_ID}/customer/delete_all`,
      delete_many: `/company/${COMPANY_ID}/customer/delete_many`,
      detail: (CUSTOMER_ID: string) => `/company/${COMPANY_ID}/customer/${CUSTOMER_ID}`,
      calls: (CUSTOMER_ID: string) => `/company/${COMPANY_ID}/call?customer=${CUSTOMER_ID}`,
      call_campaign_customer: (CUSTOMER_ID: string) =>
        `/company/${COMPANY_ID}/customer/${CUSTOMER_ID}/call_campaign_customer`,
    },
    customer_note: (CUSTOMER_ID: string) => ({
      list: `/company/${COMPANY_ID}/customer/${CUSTOMER_ID}/customer_note`,
      detail: (CUSTOMER_NOTE_ID: string) =>
        `/company/${COMPANY_ID}/customer/${CUSTOMER_ID}/customer_note/${CUSTOMER_NOTE_ID}`,
    }),
    customer_group: {
      create: `/company/${COMPANY_ID}/customer_group`,
      list: `/company/${COMPANY_ID}/customer_group`,
      listCustomer: (CUSTOMER_GROUP_ID: string) =>
        `/company/${COMPANY_ID}/customer_group/${CUSTOMER_GROUP_ID}/customer`,
      detail: (CUSTOMER_GROUP_ID: string) =>
        `/company/${COMPANY_ID}/customer_group/${CUSTOMER_GROUP_ID}`,
    },
    business_group: {
      statisticize: `/company/${COMPANY_ID}/business_group/statisticize`,
      import: `/company/${COMPANY_ID}/business_group/list_object`,
    },
    facebook_group: {
      list: `/company/${COMPANY_ID}/facebook_group`,
      detail: (FACEBOOK_GROUP_ID: string) =>
        `/company/${COMPANY_ID}/facebook_group/${FACEBOOK_GROUP_ID}`,
      import: (FACEBOOK_GROUP_ID: string) =>
        `/company/${COMPANY_ID}/facebook_group/${FACEBOOK_GROUP_ID}/import_list_object`,
    },
    calls: {
      list: `/company/${COMPANY_ID}/call`,
      filters: `/company/${COMPANY_ID}/call/filter_columns`,
      export_excel: `/company/${COMPANY_ID}/call/export_excel`,
    },
    labels: `/company/${COMPANY_ID}/customer_labels`,
    agent_campaign: {
      list: `/company/${COMPANY_ID}/agent_call_campaign`,
      pop: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_runner/pop`,
      pop_agent: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_runner/pop_agent`,
      revert: (AGENT_CAMPAIGN_ID: string, AGENT_CAMPAIGN_CUSTOMER_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_runner/${AGENT_CAMPAIGN_CUSTOMER_ID}/revert`,
      updateCustomerRunner: (AGENT_CAMPAIGN_ID: string, AGENT_CAMPAIGN_CUSTOMER_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_runner/${AGENT_CAMPAIGN_CUSTOMER_ID}`,
      detail: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}`,
      settings: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/settings`,
      import_excel: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/import_excel`,
      import_groups: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/import_groups`,
      list_agent_call_scenario: `/company/${COMPANY_ID}/call_agent_scenario`,
      detail_agent_call_scenario: (AGENT_CALL_SCENARIO_ID: string) =>
        `/company/${COMPANY_ID}/call_agent_scenario/${AGENT_CALL_SCENARIO_ID}`,
      customers: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer`,
      customer: (AGENT_CAMPAIGN_ID: string, AGENT_CAMPAIGN_CUSTOMER_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer/${AGENT_CAMPAIGN_CUSTOMER_ID}`,
      action: (AGENT_CALL_CAMPAIGN_ID: string) => ({
        revive: `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CALL_CAMPAIGN_ID}/customer/revive`,
        finish: `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CALL_CAMPAIGN_ID}/customer/finish`,
      }),
      report: (AGENT_CALL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CALL_CAMPAIGN_ID}/report`,
      report_chart: (AGENT_CALL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CALL_CAMPAIGN_ID}/report-chart`,
      report_agent: (AGENT_CALL_CAMPAIGN_ID: string, TYPE: string, DATA: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CALL_CAMPAIGN_ID}/report/report_agent?type=${TYPE}&${TYPE}=${DATA}`,
      report_filters: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_report/filter_columns`,
      reportOfCustomer: (AGENT_CAMPAIGN_ID: string, AGENT_CAMPAIGN_CUSTOMER_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_report/${AGENT_CAMPAIGN_CUSTOMER_ID}`,
      report_detail: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_report`,
      listeningBlocks: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_report/listen_blocks`,
      reportOfCustomerExportExcel: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_report/export_excel`,
      labels: (AGENT_CAMPAIGN_ID: string) => ({
        list: `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_labels`,
        detail: (CAMPAIGN_CUSTOMER_LABEL_ID: string) =>
          `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/customer_labels/${CAMPAIGN_CUSTOMER_LABEL_ID}`,
      }),
      export_excel: (AGENT_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/export_excel`,
      list_object: (AGENT_CAMPAIGN_ID: string) => ({
        import_list_object: `/company/${COMPANY_ID}/agent_call_campaign/${AGENT_CAMPAIGN_ID}/import_list_object`,
      }),
    },
    dial_campaign: {
      list: `/company/${COMPANY_ID}/dial_campaign`,
      create: `/company/${COMPANY_ID}/dial_campaign`,
      detail: (DIAL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}`,
      delete: (DIAL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}`,
      settings: (DIAL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/settings`,
      customers: (DIAL_CAMPAIGN_ID: string) => ({
        revive: `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/customer/revive`,
        finish: `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/customer/finish`,
        list: `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/customer`,
        details: (DIAL_CAMPAIGN_CUSTOMER_ID: string) => ({
          report: `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/customer/${DIAL_CAMPAIGN_CUSTOMER_ID}/report`,
        }),
      }),
      import_excel: (DIAL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/customer/import_excel`,
      export_excel: (DIAL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/customer/export_excel`,
      sample_excel: (DIAL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/customer/sample_excel`,
      scenario: {
        detail: (DIAL_CALL_SCENARIO_ID: string) =>
          `/company/${COMPANY_ID}/dial_agent_scenario/${DIAL_CALL_SCENARIO_ID}`,
      },
      report: (DIAL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/report`,
      report_dial: (DIAL_CAMPAIGN_ID: string, TYPE: string, DATA: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/report/report_dial?type=${TYPE}&${TYPE}=${DATA}`,
      report_chart: (DIAL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/report-chart`,
      register: (DIAL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/register`,
      unregister: (DIAL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/dial_campaign/${DIAL_CAMPAIGN_ID}/unregister`,
    },
    call_campaign: {
      list: `/company/${COMPANY_ID}/call_campaign`,
      create: `/company/${COMPANY_ID}/call_campaign`,
      delete: (CALL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}`,
      detail: (CALL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}`,
      demo: (CALL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer/demo`,
      demo_landing_page: (COMPANY_ID_CUSTOM: string, CALL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID_CUSTOM}/call_campaign/${CALL_CAMPAIGN_ID}/customer_demo`,
      export_excel: (CALL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/export_excel`,
      settings: (CALL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/settings`,
      report: (CALL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/report`,
      report_chart: (CALL_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/report-chart`,
      update: `/company/${COMPANY_ID}/call_campaign`,
      customers: (CALL_CAMPAIGN_ID: string) => ({
        list: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer`,
        voicemail: (CALL_CAMPAIGN_CUSTOMER_ID: string) =>
          `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer/${CALL_CAMPAIGN_CUSTOMER_ID}/voicemail`,
        import_excel: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/import_excel`,
        import_groups: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/import_groups`,
        filters: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_report/filter_columns`,
        report: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_report`,
        reportOfCustomer: (CALL_CAMPAIGN_CUSTOMER_ID: string) =>
          `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_report/${CALL_CAMPAIGN_CUSTOMER_ID}`,
        reportOfCustomerExportExcel: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_report/export_excel`,
        reportVoiceMailExportExcel: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_report/export_voicemail_report`,
        reportOfCustomerExportGroup: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_report/export_group`,
        listeningBlocks: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_report/listen_blocks`,
        count_contacts_uncreated: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_report/contacts_count`,
        create_contacts_from_campaign: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_report/created_contacts`,
      }),

      list_object: (CALL_CAMPAIGN_ID: string) => ({
        import_list_object: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/import_list_object`,
      }),

      labels: (CALL_CAMPAIGN_ID: string) => ({
        list: `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_labels`,
        detail: (CAMPAIGN_CUSTOMER_LABEL_ID: string) =>
          `/company/${COMPANY_ID}/call_campaign/${CALL_CAMPAIGN_ID}/customer_labels/${CAMPAIGN_CUSTOMER_LABEL_ID}`,
      }),
    },
    routing: {
      carrier: {
        list: `/carrier`,
      },
      setting: {
        list: `/company/${COMPANY_ID}/callcentersetting`,
        detail: `/company/${COMPANY_ID}/callcentersetting`,
      },
      extension: `/company/${COMPANY_ID}/callcenterextension`,
      record: {
        list: `/company/${COMPANY_ID}/callcenterrecord`,
        create: `/company/${COMPANY_ID}/callcenterrecord`,
        detail: (RECORD_ID: string) => `/company/${COMPANY_ID}/callcenterrecord/${RECORD_ID}`,
        delete: (RECORD_ID: string) => `/company/${COMPANY_ID}/callcenterrecord/${RECORD_ID}`,
      },
      hotline: {
        list: `/company/${COMPANY_ID}/callcenterhotline`,
        inbound: (HOTLINE_ID: any) =>
          `/company/${COMPANY_ID}/callcenterhotline/${HOTLINE_ID}/inbound`,
        outbound: (HOTLINE_ID: any) =>
          `/company/${COMPANY_ID}/callcenterhotline/${HOTLINE_ID}/outbound`,
      },
      queue: {
        list: `/company/${COMPANY_ID}/callcenterqueue`,
        create: `/company/${COMPANY_ID}/callcenterqueue`,
        detail: (QUEUE_ID: string) => `/company/${COMPANY_ID}/callcenterqueue/${QUEUE_ID}`,
        delete: (QUEUE_ID: string) => `/company/${COMPANY_ID}/callcenterqueue/${QUEUE_ID}`,
        tier: {
          list: (QUEUE_ID: string) => `/company/${COMPANY_ID}/callcenterqueue/${QUEUE_ID}/tier`,
          create: (QUEUE_ID: string) => `/company/${COMPANY_ID}/callcenterqueue/${QUEUE_ID}/tier`,
          detail: (QUEUE_ID: string, TIER_ID: string) =>
            `/company/${COMPANY_ID}/callcenterqueue/${QUEUE_ID}/tier/${TIER_ID}`,
          delete: (QUEUE_ID: string, TIER_ID: string) =>
            `/company/${COMPANY_ID}/callcenterqueue/${QUEUE_ID}/tier/${TIER_ID}`,
        },
      },
      ivr: {
        list: `/company/${COMPANY_ID}/callcenterivr`,
        create: `/company/${COMPANY_ID}/callcenterivr`,
        detail: (IVR_ID: string) => `/company/${COMPANY_ID}/callcenterivr/${IVR_ID}`,
        delete: (IVR_ID: string) => `/company/${COMPANY_ID}/callcenterivr/${IVR_ID}`,
      },
      timecondition: {
        list: `/company/${COMPANY_ID}/callcentertimecondition`,
        create: `/company/${COMPANY_ID}/callcentertimecondition`,
        detail: (TIME_ID: string) => `/company/${COMPANY_ID}/callcentertimecondition/${TIME_ID}`,
        delete: (TIME_ID: string) => `/company/${COMPANY_ID}/callcentertimecondition/${TIME_ID}`,
        option: {
          list: (TIME_ID: string) =>
            `/company/${COMPANY_ID}/callcentertimecondition/${TIME_ID}/option`,
          create: (TIME_ID: string) =>
            `/company/${COMPANY_ID}/callcentertimecondition/${TIME_ID}/option`,
          uptate: (TIME_ID: string, OPTION_ID: string) =>
            `/company/${COMPANY_ID}/callcentertimecondition/${TIME_ID}/option/${OPTION_ID}`,
          delete: (TIME_ID: string, OPTION_ID: string) =>
            `/company/${COMPANY_ID}/callcentertimecondition/${TIME_ID}/option/${OPTION_ID}`,
        },
      },
    },
    time_range_condition: {
      list: `/company/${COMPANY_ID}/time_range_condition`,
      detail: (TIME_RANGE_CONDITION_ID: string) =>
        `/company/${COMPANY_ID}/time_range_condition/${TIME_RANGE_CONDITION_ID}`,
    },
    time_condition: {
      list: `/company/${COMPANY_ID}/time_condition`,
      detail: (TIME_CONDITION_ID: string) =>
        `/company/${COMPANY_ID}/time_condition/${TIME_CONDITION_ID}`,
    },
    api_key: {
      list: (PROFILE_ID: string) => `/profile/${PROFILE_ID}/apikey`,
      detail: (PROFILE_ID: string, API_KEY: string) => `/profile/${PROFILE_ID}/apikey/${API_KEY}`,
    },
    viet_qr: {
      payment: `/company/${COMPANY_ID}/vietqr/payment`,
      check_order: (order_id: string) => `/company/${COMPANY_ID}/vietqr/${order_id}/check_order`,
    },
    employee: {
      list: `/company/${COMPANY_ID}/profile`,
    },
    employee2: {
      list: `/company/${COMPANY_ID}/profilev3`,
    },
    hotline: {
      list: `/company/${COMPANY_ID}/hotline`,
    },
    user: {
      details: `/company/${COMPANY_ID}/detail_user`,
      detail: (USER_ID: string) => `/company/${COMPANY_ID}/detail_user/${USER_ID}`,
    },
    tax: {
      detail: `/company/${COMPANY_ID}/tax`,
    },
    sms_official_account: {
      list: `/company/${COMPANY_ID}/sms_official_account`,
      create: (ID_COMPANY: string) => `/company/${ID_COMPANY}/sms_official_account`,
      delete: (SMS_ID: string) => `/company/${COMPANY_ID}/sms_official_account/${SMS_ID}`,
    },
    sms_template: {
      list: `/company/${COMPANY_ID}/sms_template`,
      create: `/company/${COMPANY_ID}/sms_template`,
      delete: (TEMPLATE_ID: string) => `/company/${COMPANY_ID}/sms_template/${TEMPLATE_ID}`,
      detail: (TEMPLATE_ID: string) => `/company/${COMPANY_ID}/sms_template/${TEMPLATE_ID}`,
    },
    sms_campaign_zalo: {
      list: `/company/${COMPANY_ID}/sms_campaign_ott`,
      create: `/company/${COMPANY_ID}/sms_campaign_ott`,
      delete: (SMS_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/sms_campaign_ott/${SMS_CAMPAIGN_ID}`,
      detail: (SMS_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/sms_campaign_ott/${SMS_CAMPAIGN_ID}`,
      run_campaign: (SMS_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/sms_campaign_ott/${SMS_CAMPAIGN_ID}/run_campaign`,
    },
    sms_campaign: {
      report: (SMS_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/sms_campaign_ott/${SMS_CAMPAIGN_ID}/sms_campaign_report`,
      customer: (SMS_CAMPAIGN_ID: string) =>
        `/company/${COMPANY_ID}/sms_campaign_ott/${SMS_CAMPAIGN_ID}/sms_campaign_customer`,
    },
    list: {
      view_list_object: `/company/${COMPANY_ID}/list/options`,
      objects: (LIST_OBJECT_ID: string) =>
        `/company/${COMPANY_ID}/list/${LIST_OBJECT_ID}/setting`,
      filter_object: (LIST_OBJECT_ID: string) =>
        `/company/${COMPANY_ID}/list/${LIST_OBJECT_ID}/setting/advance_filter`,
    },
    property: {
      get_all_property: (MODLE_NAME: string) =>
        `/company/${COMPANY_ID}/property/all?app_model_name=${MODLE_NAME}`,
      list_option_value: (PROPERTY_ID: string) =>
        `/company/${COMPANY_ID}/property/${PROPERTY_ID}/option`,
    },
    enterprise: {
      filter_enterprise: `/company/${COMPANY_ID}/enterprise/advance_filter`,
    },
    contact: {
      create_contact: `/company/${COMPANY_ID}/contact`,
    }
  };
};

export default axiosInstance;
